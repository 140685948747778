import React from 'react';
import '../home/App.css';
import '../../fonts/Fonts.css'
import {Helmet} from 'react-helmet'

function About(){
	return (<div className="about-container">
	<Helmet htmlAttributes={{ class : "with-bg" }}/>

		About Component


		</div>

		);

}

export default About;